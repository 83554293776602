import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BetaContact from "../components/BetaContact";

const BetaPage = () => (
    <Layout>
        <SEO title="Sign up for the Galaxy Pro Researcher Beta" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-half">
                            <h1 className="title has-text-warning">Galaxy Pro Researcher Beta Application</h1>
                            <h2 className="subtitle">Provide your contact info and we will be in touch!</h2>
                            <p>
                                During the beta period, the service will be free and following the official launch, as a
                                beta tester, you will receive a 25% discount on your subscription for life!* All this in
                                exchange for sharing your experiences and opinions.
                            </p>
                        </div>
                        <div className="column">
                            <div className="container has-text-centered">
                                <BetaContact />
                            </div>
                        </div>
                    </div>
                    <p className="is-size-6">*Fine print</p>
                    <p className="is-size-7">
                        Yes, there always seems to be a catch on the good deals. In this case, the{" "}
                        <i>discount for life</i> is valid only for continuous, uninterrupted subscription.
                    </p>
                </div>
            </div>
        </section>
    </Layout>
);

export default BetaPage;
